.contact-ctn {
	position: absolute;
	top: -100vh;
	left: 0;
	height: 100vh;
	width: 100vw;
}
.contact-title {
	color: #faf5e9;
	font-size: 200px;
	font-family: "Noto Serif", sans-serif;
	font-weight: 400;
	width: 50vw;
	position: relative;
	top: 25vh;
	left: 7.5vw;
}
.contact-sub {
	color: #faf5e9;
	font-family: "Noto Serif", sans-serif;
}
.contact-ctn h3,
.contact-ctn h4 {
	color: #faf5e9;
	font-family: "Noto Serif", sans-serif;
}
.contact-title-ctn {
	color: #faf5e9;
	width: 50vw;
	position: relative;
	top: 5vh;
	left: 7.5vw;
}
.contact-txt {
	color: #faf5e9;
	width: 50vw;
	position: relative;
	top: 30vh;
	left: 7.5vw;
}
i {
	margin-right: 0.5vw;
	width: 16px;
	text-align: center;
}
.contact-social {
	font-style: italic;
}
.contact-social:hover {
	text-decoration: line-through;
}

/* Responsive */
@media screen and (max-width: 1700px) {
	.contact-title {
		width: 90vw;
	}
}
@media screen and (max-width: 1250px) {
	.contact-title {
		font-size: 200px;
	}
}
@media screen and (max-width: 1000px) {
	.contact-ctn {
		left: 7.5vw;
	}
	.contact-title {
		font-size: 150px;
	}
	.contact-txt {
		width: 60vw;
	}
}
@media screen and (max-width: 700px) {
	.contact-ctn {
		top: -100vh;
		left: 0;
	}
	.contact-title {
		width: 100%;
		font-size: 100px;
	}
	.contact-txt {
		width: 75vw;
	}
}
@media screen and (max-width: 500px) {
	.contact-ctn {
		top: -100vh;
	}
	.contact-title {
		font-size: 75px;
	}
}

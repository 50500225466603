.burger-menu {
	position: fixed;
	right: 2.5vw;
	top: -98.5vh;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: end;
}

.menu-icon {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 30px;
	height: 30px;
	cursor: pointer;
	z-index: 10;
	background-color: black;
	padding: 10px;
	border-radius: 50%;
}

.menu-icon div {
	width: 100%;
	height: 4px;
	background-color: #faf5e9;
	transition: all 0.3s ease;
}

.menu-icon.open .bar1 {
	transform: rotate(-45deg) translate(-5px, 9px);
}

.menu-icon.open .bar2 {
	opacity: 0;
}

.menu-icon.open .bar3 {
	transform: rotate(45deg) translate(-5px, -9px);
}

.menu-content {
	display: none;
	position: absolute;
	top: 40px;
	right: 0;
	background-color: #faf5e9;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
	border-radius: 5px;
	z-index: 9;
	text-align: center;
	width: 15vw;
	height: 50vh;
}

.menu-content.show {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.menu-item {
	display: block;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	text-decoration: none;
	color: black;
	z-index: 20;
}

.menu-item:hover {
	text-decoration: line-through;
}

/* Responsive */
@media screen and (max-width: 1500px) {
	.menu-content {
		width: 20vw;
	}
}
@media screen and (max-width: 1000px) {
	.menu-content {
		width: 30vw;
	}
}
@media screen and (max-width: 700px) {
	.burger-menu {
		top: -95vh;
	}
	.menu-content {
		height: 80vh;
	}
	.menu-content.show {
		justify-content: center;
		gap: 10vh;
	}
}
@media screen and (max-width: 500px) {
	.menu-content {
		width: 40vw;
	}
}
@media screen and (max-width: 350px) {
	.menu-content {
		width: 50vw;
	}
}

.about-ctn {
	position: absolute;
	top: -100vh;
	left: 0;
	height: 100vh;
	width: 100vw;
	overflow: auto;
}
.about-title {
	color: #faf5e9;
	font-size: 150px;
	font-family: "Noto Serif", sans-serif;
}
.about-sub {
	color: #faf5e9;
	font-family: "Noto Serif", sans-serif;
}
.about-ctn h3,
.about-ctn h4 {
	color: #faf5e9;
	font-family: "Noto Serif", sans-serif;
}
.about-img {
	width: 25vw;
	height: 80vh;
	object-fit: cover;
	border-bottom-left-radius: 40px;
	position: absolute;
	top: -1vh;
	right: 0;
}
.about-title-ctn {
	color: #faf5e9;
	width: 50vw;
	position: relative;
	top: 5vh;
	left: 7.5vw;
	z-index: 0;
}
.about-sub-ctn {
	color: #faf5e9;
	width: 40vw;
	position: relative;
	top: 5vh;
	left: 7.5vw;
	z-index: -1;
}
.about-txt-ctn {
	color: #faf5e9;
	width: 50vw;
	position: relative;
	top: 10vh;
	left: 7.5vw;
	z-index: -1;
}
.about-xp-ctn {
	color: #faf5e9;
	width: 50vw;
	position: relative;
	top: 10vh;
	left: 7.5vw;
	display: flex;
	justify-content: center;
	text-align: center;
	gap: 5vw;
	padding: 0 0 10vh 0;
	z-index: -1;
}
.about-link-flex {
	display: flex;
	width: 50vw;
	gap: 10vw;
	justify-content: center;
	position: relative;
	top: 10vh;
	left: 7.5vw;
	margin: 5vh 0;
}
.about-link-1,
.about-link-2 {
	display: flex;
	flex-direction: column;
	gap: 2vh;
	font-style: italic;
	background-color: #faf5e9;
	color: black;
	border: none;
	border-radius: 40px;
	padding: 20px 40px;
	transition: all 0.5s;
}
.about-link-1:hover,
.about-link-2:hover {
	padding: 20px 60px;
	transition: all 0.5s;
}
.about-skills-flex {
	display: flex;
	gap: 5vw;
}
.about-skills {
	display: flex;
	flex-direction: column;
	width: 12vw;
}

/* Responsive */
@media screen and (max-width: 1600px) {
	.about-title {
		font-size: 125px;
	}
	.about-sub {
		font-size: 40px;
	}
}
@media screen and (max-width: 1300px) {
	.about-ctn {
		left: 2.5vw;
	}
	.about-title {
		font-size: 90px;
	}
	.about-sub {
		font-size: 35px;
	}
	.about-img {
		width: 30vw;
	}
}
@media screen and (max-width: 1000px) {
	.about-ctn {
		left: 5vw;
	}
	.about-title {
		font-size: 75px;
	}
	.about-sub {
		font-size: 30px;
	}
	.about-skills {
		width: 15vw;
	}
	.about-img {
		width: 35vw;
	}
	.about-link-1,
	.about-link-2 {
		padding: 10px 20px;
	}
	.about-link-1:hover,
	.about-link-2:hover {
		padding: 10px 20px;
	}
}
@media screen and (max-width: 800px) {
	.about-title-ctn {
		top: 15vh;
	}
	.about-sub-ctn {
		top: 20vh;
		z-index: 0;
	}
	.about-txt-ctn {
		top: 25vh;
	}
	.about-img {
		top: 5vh;
		right: 5vw;
		height: 30vh;
		width: 30vw;
		border-radius: 0;
	}
	.about-title-ctn,
	.about-sub-ctn,
	.about-txt-ctn,
	.about-xp-ctn {
		width: 80%;
	}
	.about-skills-ctn {
		width: 80vw;
		margin: 15vh 0;
	}
	.about-skills-flex {
		justify-content: center;
		width: 80vw;
	}
	.about-skills {
		width: 40vw;
	}
	.about-link-flex {
		width: 80%;
		top: 25vh;
	}
}
@media screen and (max-width: 700px) {
	.about-ctn {
		left: 0;
	}
	.about-title-ctn {
		top: 20vh;
		left: 0;
	}
	.about-title {
		font-size: 60px;
	}
	.about-sub-ctn {
		top: 25vh;
		left: 0;
	}
	.about-sub {
		font-size: 25px;
	}
	.about-txt-ctn {
		top: 30vh;
		left: 0vh;
	}
	.about-img {
		top: 10vh;
		right: 5vw;
	}
	.about-title-ctn,
	.about-sub-ctn,
	.about-txt-ctn,
	.about-xp-ctn {
		left: 0;
		width: 80%;
		margin: auto;
	}
	.about-skills-ctn {
		width: 80vw;
		margin: 20vh 0 10vh;
	}
	.about-skills {
		width: 40vw;
	}
	.about-link-flex {
		width: 80%;
		left: 0;
		margin: 5vh auto;
		top: 30vh;
	}
}

@media screen and (max-width: 700px) {
	.about-ctn {
		left: 0;
	}
	.about-title-ctn {
		top: 20vh;
		left: 0;
	}
	.about-title {
		font-size: 50px;
	}
	.about-sub-ctn {
		top: 25vh;
		left: 0;
	}
	.about-sub {
		font-size: 22px;
	}
	.about-txt-ctn {
		top: 30vh;
		left: 0vh;
	}
	.about-img {
		height: 20vh;
		width: 30vw;
	}
	.about-title-ctn,
	.about-sub-ctn,
	.about-txt-ctn,
	.about-xp-ctn {
		left: 0;
		width: 90%;
		margin: auto;
	}
	.about-skills-ctn {
		width: 90vw;
		margin: 20vh auto 5vh;
	}
	.about-skills-flex {
		width: 100%;
	}
	.about-skills {
		width: 40vw;
	}
	.about-link-flex {
		width: 90%;
		top: 30vh;
	}
}
@media screen and (max-width: 500px) {
}

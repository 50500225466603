/* Import des polices de caractère */
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Roboto:wght@300;400;700&family=Tenor+Sans&display=swap");

html {
	width: 100%;

	scroll-behavior: smooth;
	margin: auto;
	overflow-x: hidden;
}
body {
	background-color: #fbfbfb;
	color: black;
	position: relative;
	overflow-x: hidden;
	overflow: hidden;
	margin: auto;
}
main {
	overflow-x: hidden;
}
section {
	scroll-behavior: smooth;
}
section h2 {
	text-align: center;
}
h1,
h2,
h3,
h4 {
	font-family: "Noto Serif", serif;
	margin: 0;
	font-size: 50px;
	color: #faf5e9;
	line-height: 110%;
}
p {
	line-height: 1.9em;
}
a,
p,
label,
div,
button {
	font-family: "Roboto", sans-serif;
	margin: 0;
}
p,
a {
	font-size: 18px;
}
h3 {
	font-size: 26px;
	margin-bottom: 25px;
}
h4 {
	font-size: 22px;
	margin-bottom: 10px;
}
ul {
	list-style: none;
	display: flex;
	margin: 0;
}
li {
	margin: 25px 0;
}
a {
	color: #faf5e9;
	text-decoration: none;
}
.flex {
	display: flex;
}
.injected-svg {
	z-index: -1;
}
.relative {
	position: relative;
	top: 0;
	left: 0;
}
.return {
	position: absolute;
	top: 2.5vh;
	right: 27vw;
	display: flex;
	color: #faf5e9;
	text-decoration: none;
	font-style: italic;
}
.return:hover {
	text-decoration: line-through;
}

/* Responsive */
@media screen and (max-width: 1700px) {
}
@media screen and (max-width: 1250px) {
	p,
	a {
		font-size: 16px;
	}
}
@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 700px) {
	p,
	a {
		font-size: 14px;
	}
}
@media screen and (max-width: 500px) {
}

/* ProjetDetail.css */
.projet-detail-container {
	position: absolute;
	top: -100vh;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
	overflow-y: auto;
}
.projet-detail-title-ctn {
	text-align: center;
	margin: 0 0 2vh;
	width: 75vw;
	min-height: 90vh;
	background-color: #faf5e9;
	position: relative;
	border-bottom-left-radius: 50px;
	border-bottom-right-radius: 50px;
}
.projet-detail-title {
	color: black;
	background-color: none;
	font-size: 125px;
	font-weight: 300;
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 25vh;
}
.projet-detail-subtitle {
	color: black;
	background-color: none;
	font-weight: 300;
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 10vh;
}
.projet-detail-quote {
	color: #faf5e9;
	margin: 0;
}
.projet-detail-txt-ctn {
	margin: 0vh 0 5vh;
}
.projet-detail-txt {
	color: #faf5e9;
	width: 40vw;
	margin: 0vh 0 5vh;
}
.projet-detail-txt h4 {
	text-align: center;
	font-size: 50px;
	font-weight: 300;
	font-style: italic;
}
.projet-detail-txt p {
	text-align: center;
}
.projet-detail-list {
	color: #faf5e9;
	width: 80vw;
	padding: 0;
	gap: 5vw;
	display: flex;
	justify-content: center;
	margin: 5vh 0;
}
.projet-detail-list li {
	text-align: center;
}
.projet-detail-list li h4 {
	text-align: center;
	font-size: 50px;
	font-weight: 300;
	font-style: italic;
	margin: 0;
}
.projet-detail-list li p div {
	margin: 1vh 0;
}
.projet-detail-underline {
	height: 2px;
	background-color: #faf5e9;
	margin: 0 0 2vh;
}
.projet-detail-link {
	display: inline-block;
	font-size: 20px;
	position: relative;
	padding: 20px 50px;
	background-color: #faf5e9;
	text-align: center;
	border: none;
	border-radius: 50px;
	cursor: pointer;
	transition: padding 0.3s ease;
	margin: 0 0 15vh 0;
}
.projet-detail-link:hover {
	padding: 20px 80px;
	transition: padding 0.3s ease;
}
.projet-detail-link a {
	color: black;
}
.projet-detail-quote {
	font-size: 50px;
	font-weight: 300;
	font-style: italic;
}
.projet-detail-video {
	width: 75vw;
	margin: 5vh 0 0;
	border-radius: 20px;
}

/* Responsive */
@media screen and (max-width: 1700px) {
}
@media screen and (max-width: 1500px) {
	.projet-detail-title {
		font-size: 100px;
	}
	.projet-detail-subtitle {
		font-size: 40px;
	}
}
@media screen and (max-width: 1250px) {
	.projet-detail-title-ctn {
		width: 80vw;
	}
	.projet-detail-video {
		width: 80vw;
	}
}
@media screen and (max-width: 1000px) {
	.projet-detail-title {
		font-size: 80px;
	}
	.projet-detail-subtitle {
		font-size: 30px;
	}
	.projet-detail-list {
		flex-direction: column;
		width: 40vw;
		gap: 1vh;
	}
	.projet-detail-txt {
		margin: 0vh 0 10vh;
	}
	.projet-detail-list li {
		text-align: left;
		margin: 0;
	}
	.projet-detail-list li h4 {
		text-align: left;
		font-size: 50px;
	}
	.projet-detail-list li p div {
		margin: 1vh 0;
	}
}
@media screen and (max-width: 850px) {
	.projet-detail-list {
		width: 50vw;
	}
}
@media screen and (max-width: 700px) {
	.projet-detail-list {
		position: relative;
		top: 15vh;
		width: 60vw;
	}
	.projet-detail-title-ctn {
		min-height: 80vh;
		bottom: -7.5vh;
		border-radius: 50px;
		width: 90vw;
	}
	.projet-detail-txt {
		width: 80vw;
		position: relative;
		top: 15vh;
	}
	.projet-detail-title {
		bottom: 40vh;
	}
	.projet-detail-subtitle {
		bottom: 30vh;
	}
	.projet-detail-video {
		width: 90vw;
		position: relative;
		top: 15vh;
	}
}
@media screen and (max-width: 500px) {
	.projet-detail-title {
		font-size: 50px;
	}
	.projet-detail-subtitle {
		font-size: 25px;
	}
	.projet-detail-list {
		width: 80vw;
		gap: 5vh;
	}
	.projet-detail-list li h4 {
		text-align: left;
		font-size: 40px;
	}
}

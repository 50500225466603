/* SkillsList.css */
.skills-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
}

.skill-item {
	display: flex;
	align-items: center;
	gap: 5px;
}

.skill-logo {
	width: 24px;
	height: 24px;
}

/* Responsive */
@media screen and (max-width: 1000px) {
	.skills-list {
		justify-content: left;
	}
}
